@import "../../utils//colors.scss";
@import "../../utils/media.scss";

.sellPointModal {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  background-color: white;
  max-height: 80%;
  z-index: 1000;
  border-radius: 2px;
  padding: 2rem 1.5rem;

  @include respond(tab-port) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 371px;
    max-height: 653px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.66);
}

.sellPointMarker {
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  transition: all 100ms ease-in-out;
  background-image: url("../../assets/pdvi.svg");

  &:hover {
    transform: scale(1.1);
  }

  &.FULL {
    background-image: url("../../assets/pdv-full.svg");
  }

  &.MID {
    background-image: url("../../assets/pdf-mid.svg");
  }

  &.NS {
    background-image: url("../../assets/pdv-empty.svg");
  }
}

.sellPointChart {
  width: 100%;
}

.chart {
  margin: 1.5rem 0;
  padding: 1rem;
  width: 100%;
  border-radius: 4px;
  border: solid 2px rgba(151, 151, 151, 0.19);
  background-color: #ffffff;

  h3 {
    font-family: "Aller", sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.75;
    color: #3a3a3a;
    text-align: left;
  }

  h4 {
    margin: 1rem 0;
    font-family: "Aller", sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.75;
    color: #3a3a3a;
    text-align: center;
  }

  .hours {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    span {
      font-family: "Aller", sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      line-height: 1.9;
      letter-spacing: normal;
      text-align: center;
      color: #a6a6a6;
    }
  }
}

.close {
  width: 17px;
  height: 17px;
  fill: #000;
  position: absolute;
  top: 14px;
  right: 20px;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  figure {
    margin-right: 5px;
  }

  .sellPointInfo {
    h3 {
      font-family: "Aller", sans-serif;
      font-size: 1.8rem;
      font-weight: bold;
      color: $black;
      text-align: left;
    }

    h4 {
      font-family: "Aller", sans-serif;
      font-size: 1.4rem;
      font-weight: normal;
      color: #ababab;
      text-align: left;
    }

    h5 {
      font-family: "Aller", sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      color: var(--buttonsSec);
      text-align: left;
    }
  }
}

.productContainer {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 2px rgba(151, 151, 151, 0.17);
  background-color: #ffffff;
  overflow-y: scroll;
  margin-top: 15px;
  max-height: 200px;
  @include respond(tab-port) {
    max-height: 254px;
  }

  .productItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: solid 1px rgba(#979797, 0.34);
  }

  .productItemName {
    font-family: "Aller", sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    color: #3a3a3a;
    width: fit-content;
  }

  .productStatus {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      font-family: "Aller", sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      text-align: right;
      color: #b8b8b8;
      margin-right: 7px;
    }
    svg {
      width: 21px;
      height: 21px;
      // border-radius: 100%;
      // &.Si {
      //   border: solid 2.1px #16d089;
      //   background-color: #f3f0f0;
      // }
      // &.No {
      //   border: solid 2.1px #ff2b67;
      //   background-color: #f3f0f0;
      // }
      // &.NS {
      //   border: solid 2.1px #c2c2c2;
      //   background-color: #f3f0f0;
      // }
    }
  }
}

.button {
  font-family: "Aller", sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 16px 20px;
  background-color: var(--buttons);
  border-radius: 4px;
  color: var(--background);
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: auto;
}

.maps {
  font-family: "Aller", sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  color: var(--background);
  text-decoration: none;
  margin-top: 15px;
}
