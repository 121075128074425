@import "../../utils/colors.scss";
@import "../../utils/animation.scss";
@import "../../utils/media.scss";

.container {
  height: 100%;
  z-index: 1000;
  position: absolute;
  width: 100%;
  padding: 15px 14px;
  background-color: var(--background);
  top: 100%;
  display: none;
  max-width: 468px;
  left: 0;

  @include respond(sm) {
    padding: 30px 14px;
    max-width: 468px;
  }

  @media (max-width: 600px) {
    overflow-y: scroll;
  }
}

.active {
  animation: slideUp3 0.5s ease forwards;
  position: fixed;
  display: flex;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @include respond(sm) {
    justify-content: space-around;
  }

  .closeContainer {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    left: 14px;

    svg {
      fill: white;
    }
  }

  .brandOwner {
    position: absolute;
    top: 14px;
    right: 14px;

    @media (max-width: 600px) {
      top: 30px;
    }

    svg,
    img {
      max-width: 80px;
    }
  }

  .title {
    margin-top: 30px;
    font-family: "Aller";
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.7;
    color: var(--description);
    @include respond(tab-port) {
      font-size: 2.7rem;
    }
  }

  .helpUsToHelp {
    margin: 20px 0;
    position: relative;
    @include respond(tab-port) {
      max-width: 468px;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   height: 2px;
    //   width: 100%;
    //   opacity: 0.31;
    //   background: #979797;
    //   top: -24.5px;
    //   left: 0;
    // }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   height: 2px;
    //   width: 100%;
    //   opacity: 0.31;
    //   background: #979797;
    //   bottom: -24.5px;
    //   left: 0;
    // }

    .innerTitle {
      font-family: "Aller";
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 0.7;
      color: var(--description);
      text-align: left;
      @include respond(tab-port) {
        font-size: 2.7rem;
      }
    }

    .description {
      margin-top: 16px;
      font-family: "Aller", sans-serif;
      font-size: 1.4rem;
      line-height: 1.36;
      color: $white;
      text-align: left;
      @include respond(tab-port) {
        font-size: 1.8rem;
      }
    }
  }

  .brands {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    div {
      width: fit-content;
      max-width: 15%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // max-width: 50px;
      // height: 25px;
      @include respond(tab-port) {
        max-width: 20%;
        // width: 20%;
        // max-width: 100px;
        // height: 100%;
      }

      img,
      svg {
        width: 100%;
        max-height: 36px;
        @include respond(tab-port) {
          max-height: 100%;
        }
      }
    }
  }
}
