.container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  min-height: 100vh;
  display: none;
}

.modal {
  position: absolute;
  width: 90%;
  max-width: 324px;
  height: 373px;
  border-radius: 2px;
  background-color: #ffffff;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modal 0.5s ease forwards;
  display: none;

  @keyframes modal {
    0% {
      top: 150%;
    }

    100% {
      top: 50%;
    }
  }
}

.active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px 20px;

  .title {
    width: 238px;
    height: 19px;
    font-family: "Aller", sans-serif;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }

  .description {
    width: 229px;
    height: 63px;
    font-family: "Aller", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #9c9c9c;
  }

  .btn {
    width: 100%;
    max-width: 294px;
    height: 51px;
    border-radius: 4px;
    background-color: var(--buttons);
    font-family: Aller;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--background);
    border: none;
    cursor: pointer;
  }
}
