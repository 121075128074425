@import "../../utils/colors.scss";
@import "../../utils/animation.scss";
@import "../../utils/media.scss";
.container {
  position: fixed;
  top: 0;
  // left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  padding: 1.6rem 1.4rem;
  background-color: var(--background);
  animation: slideUp2 0.5s ease forwards;
  @include respond(tab-port) {
    max-width: 375px;
    max-height: 499px;
    right: 30px;
    top: 60px;
    animation: fadeIn 0.5s ease-in-out 200ms 1 normal backwards;
  }
}
.arrow {
  cursor: pointer;
  margin-bottom: 1rem;
  width: 100%;
  height: auto;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  svg {
    fill: #fff;
  }
  .back {
    width: 22px;
    height: 19px;
  }
}
